<template>
    <b-row>
        <b-col md="12">
            <b-card>
                <validation-observer ref="simpleRules">
                    <b-form>
                        <b-row>

                            <!-- Generate Coupon Code Button -->
                            <b-col md="12">
                                <b-button
                                    @click="generateDicountCouponCode"
                                    size="sm"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                >
                                    GENERAR UN CÓDIGO DE CUPÓN
                                </b-button>
                                <hr>
                            </b-col>

                            <!-- Code -->
                            <b-col md="4">
                                <b-form-group
                                    label="Código"
                                    label-for="code">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="código de cupón"
                                    >
                                        <b-form-input
                                            id="code"
                                            v-model="code"
                                            placeholder="Código de cupón ..."
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Coupon Type -->
                            <b-col md="4">
                                <b-form-group
                                    label="Seleccionar tipo de descuento"
                                    label-for="coupon_type">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="tipo de descuento"
                                    >
                                        <v-select
                                            id="coupon_type"
                                            v-model="type"
                                            :state="errors.length > 0 ? false : null"
                                            :options="types"
                                            label="name"
                                        />

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Discount Number -->
                            <b-col md="4">
                                <b-form-group
                                    label="Importe (S/ o %)"
                                    label-for="discount_number">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="importe de cupón"
                                    >
                                        <b-form-input
                                            id="discount_number"
                                            v-model="discount_number"
                                            placeholder="importe del cupón ..."
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Expiration Date -->
                            <b-col md="4">
                                <b-form-group
                                    label="Fecha de caducidad"
                                    label-for="expiration_date">

                                    <flat-pickr
                                        id="expiration_date"
                                        v-model="expiration_date"
                                        class="form-control"
                                        :config="config"
                                        placeholder="YYYY-MM-DD"
                                    />

                                </b-form-group>
                            </b-col>

                            <!-- Submit Button -->
                            <b-col cols="12">
                                <b-button
                                    variant="primary"
                                    type="submit"
                                    @click.prevent="validationForm"
                                >
                                    AGREGAR
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import Toast from 'vue-toastification';
    import vSelect from 'vue-select';
    import cc  from 'coupon-code';
    import axios from '@axios';
    import Vue from 'vue';

    Vue.use(Toast);

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            flatPickr,
            vSelect
        },
        directives: {
            Ripple
        },
        data() {
            return {
                required,
                types: [],

                code: '',
                type: null,
                discount_number: 0,
                expiration_date: null,
                config: {
                    enableTime: false,
                    locale: Spanish
                }
            }
        },
        beforeMount (){
            this.getCouponTypes();
        },
        methods: {
            async getCouponTypes (){
                let response = await axios.get('/coupon-types/select');
                this.types = response.data;
            },
            generateDicountCouponCode (){
                this.code = cc.generate(); 
            },
            validationForm() {
                this.$refs.simpleRules.validate()
                    .then(success => {
                        if (success) {

                            let data = {
                                code: this.code,
                                coupon_type_id: this.type.id,
                                discount_number: this.discount_number,
                                expiration_date: this.expiration_date
                            };

                            axios.post('/coupon/create', data)
                                .then(response => {

                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: response.data.message,
                                            icon: 'CheckIcon',
                                            variant: 'success'
                                        }
                                    });

                                    this.code = '';
                                    this.type = null;
                                    this.discount_number = 0;
                                    this.expiration_date = null;

                                    this.$refs['simpleRules'].reset();

                                    setTimeout(() => {
                                        this.$router.push({ name: 'coupons-edit', params: { id: response.data.id } });
                                    }, 3000);

                                })
                                .catch( (err) => {
                                    let message = err.response.data.message ? err.response.data.message : 'Error al crear el cupón de descuento.';
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: message,
                                            icon: 'AlertTriangleIcon',
                                            variant: 'danger'
                                        }
                                    });
                                });

                        }
                    });
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>